import { format } from 'date-fns';
import { Heading, Paragraph } from 'modules/app';
import React from 'react';
import * as styles from 'modules/legal/style/legal.style';
import { FormattedMessage } from 'gatsby-plugin-intl';

interface Props {
  documents: LegalItem[];
  title: string;
}

export const Header: React.FC<Props> = ({ documents, title }) => (
  <section css={styles.HeadingWrapper}>
    <div css={styles.wrapper}>
      <div css={styles.contentWrapper}>
        <Paragraph size="fluidSmall" fontFamily="primary" css={styles.date}>
          LAST UPDATE:{' '}
          {format(new Date(documents[0].last_publication_date), 'MMM do, yyyy')}
        </Paragraph>

        <Heading size="fluidHuge" as="h1" css={styles.HeadingContent}>
          <FormattedMessage id={title} />
        </Heading>
      </div>
    </div>
  </section>
);
