import React from 'react';
import { ListItem, Header } from './components';
import * as styles from './style/legal.style';
import Logo from 'assets/icons/logoGrey.svg';
import { Navbar } from 'modules/app/components/shared/Navbar';
import { useQueryDocuments } from './hooks';

export const PrivacyPolicyPage: React.FC = () => {
  const documents = useQueryDocuments();

  return (
    <>
      <Navbar negative />
      <Header title="legal.privacyPolicy" documents={documents} />
      <ListItem category="Privacy Policy" documents={documents} />
      <section css={styles.logoWrapper}>
        <Logo css={styles.logo} />
      </section>
    </>
  );
};
