import { graphql, useStaticQuery } from 'gatsby';

export const useQueryDocuments = () => {
  const query = useStaticQuery(
    graphql`
      query cookieQuery {
        allPrismicLegalContent {
          nodes {
            data {
              content {
                description {
                  raw
                }
                title {
                  text
                }
              }
              category_select
            }
            last_publication_date
          }
        }
      }
    `,
  );

  const documents: LegalItem[] = query.allPrismicLegalContent.nodes;

  return documents;
};
