import { Heading, Paragraph } from 'modules/app';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import * as styles from 'modules/legal/style/legal.style';

interface Props {
  category: LegalCategories;
  documents: LegalItem[];
}

export const ListItem: React.FC<Props> = ({ category, documents }) => {
  return (
    <section css={styles.itemWrapper}>
      <div css={styles.contentWrapper}>
        {documents
          .filter((item) => item.data.category_select === category)
          .map((item) => (
            <>
              {item.data.content.map((item, i) => (
                <React.Fragment key={i}>
                  <hr css={styles.Divider} />
                  <Heading as="h4" size="fluidMedium" css={styles.title}>
                    {item.title.text}
                  </Heading>
                  <Paragraph
                    size="fluidSmall"
                    fontFamily="primary"
                    css={styles.content}
                  >
                    <RichText render={item.description.raw} />
                  </Paragraph>
                </React.Fragment>
              ))}
            </>
          ))}
      </div>
    </section>
  );
};
